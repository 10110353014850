@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

.app-container {
  background-color: #eff5f9;
  backdrop-filter: blur(5px);
}


body {
  margin: 0;
  padding: 0;
  min-height: 100vh;

  margin: 0;
  font-family: 'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff5f9;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e2e8f0' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
} 

/* Avatar section animations */
.avatar-section {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, margin 0.3s ease-in-out;
}

.avatar-section-collapsed {
  max-height: 0;
  opacity: 0;
  margin-top: 0;
}

.avatar-section-expanded {
  max-height: 3rem;
  opacity: 1;
  margin-top: 0.5rem;
  padding: 0.25rem 0;
}

/* Card hover effects */
.group:hover {
  transform: translateY(-2px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Status button hover effects */
.status-button {
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.status-button:hover {
  transform: scale(1.02);
}

/* Avatar hover effects */
.avatar-group-item {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.avatar-group-item:hover {
  transform: scale(1.1);
  z-index: 10;
}

/* Checkbox effects for Preferences Page */
@keyframes checkmark-pop {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}

/* Download menu styles */
.download-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  min-width: 120px;
  overflow: hidden;
  font-size: 10px;
  border: 1px solid rgb(156 163 175 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.download-menu-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  color: #374151;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 10px;
  /* white-space: nowrap; */
  width: 100%;
  background-color: transparent;
}

.download-menu-item:hover {
  background-color: #e0e2e8;
  white-space: nowrap;
  width: 100%;
}

.download-menu-item svg {
  margin-right: 4px;
  width: 10px;
  height: 10px;
  color: #6B7280;
}

/* Smooth transitions */
* {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #9CA3AF #E5E7EB;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #E5E7EB;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9CA3AF;
  border-radius: 3px;
}

.custom-timepicker .fui-TimePicker__expandIcon {
  display: none !important;
}